import { Component } from "react";
import React from "react";
// import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
// import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import { Dropdown as BSDropdown } from "react-bootstrap";

class Example extends Component {
  
  render() {
    
    return(
        <div style={{marginTop:80}}>
            gjgkjjc
<div class="dropdown">
  <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    Dropdown link
  </a>

  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="/">Action</a></li>
    <li><a class="dropdown-item" href="/">Another action</a></li>
    <li><a class="dropdown-item" href="/">Something else here</a></li>
  </ul>
</div>
<h1>jfjfjxjxnjxjnxj</h1>

{/* 
<div className="dropdown">
  <button className="link">information</button>
  <div className="dropdown-menu">dropdown content</div>
</div> */}
<div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Dropdown button
  </button>
  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#">Action</a></li>
    <li><a class="dropdown-item" href="#">Another action</a></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li>
  </ul>
</div>

        </div>
    )
}}
export default Example;



// import React,{useState} from 'react';
// import {NavLink, Link} from 'react-router-dom';
// import {FiAlignRight,FiXCircle,FiChevronDown } from "react-icons/fi";
// // import logo from '../../img/logo.png';
// const Example = () => {
//     const [isMenu, setisMenu] = useState(false);
//     const [isResponsiveclose, setResponsiveclose] = useState(false);
//     const toggleClass = () => {
//       setisMenu(isMenu === false ? true : false);
//       setResponsiveclose(isResponsiveclose === false ? true : false);
//   };
//     let boxClass = ["main-menu menu-right menuq1"];
//     if(isMenu) {
//         boxClass.push('menuq2');
//     }else{
//         boxClass.push('');
//     }
//     const [isMenuSubMenu, setMenuSubMenu] = useState(false);
//     const toggleSubmenu = () => {
//       setMenuSubMenu(isMenuSubMenu === false ? true : false);
//     };
//     let boxClassSubMenu = ["sub__menus"];
//     if(isMenuSubMenu) {
//         boxClassSubMenu.push('sub__menus__Active');
//     }else {
//         boxClassSubMenu.push('');
//     }
//     return (
//     <header className="header__middle">
//         <div className="container">
//             <div className="row">
//                 {/* Add Logo  */}
//                 <div className="header__middle__logo">
//                     <NavLink exact activeClassName='is-active' to="/">
//                         <img src="" alt="logo" /> 
//                     </NavLink>
//                 </div>
//                 <div className="header__middle__menus">
//                     <nav className="main-nav " >
//                     {/* Responsive Menu Button */}
//                     {isResponsiveclose === true ? <> 
//                         <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiXCircle />   </span>
//                     </> : <> 
//                         <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiAlignRight />   </span>
//                     </>}
//                     <ul className={boxClass.join(' ')}>
//                         <li  className="menu-item" >
//                             <NavLink exact activeClassName='is-active' onClick={toggleClass} to={`/`}> Home </NavLink> 
//                         </li>
//                         <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/About`}> About </NavLink> </li>
//                         <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows" > <Link to="#"> Shop <FiChevronDown /> </Link>
//                             <ul className={boxClassSubMenu.join(' ')} > 
//                                 <li> <NavLink onClick={toggleClass} activeClassName='is-active'  to={`/Online`}> Online Shop </NavLink> </li>
//                                 <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Offline`}> Offline Shop </NavLink> </li>
//                             </ul>
//                         </li>
//                         <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Contact`}> Contact </NavLink> </li>
//                     </ul>
//                     </nav>     
//                 </div>   
//             </div>
// 	    </div>
//     </header>
//     )
// }
// export default Example;