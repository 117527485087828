import React from "react";
import './Footer.css';
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { TfiYoutube } from "react-icons/tfi";
import { BsTwitter } from "react-icons/bs";
import ChatWithUs from "./Chatwithus.jsx";
import { Next } from "react-bootstrap/esm/PageItem";
const Footers = () =>{
    return(
<div className="footercontainer">
<footer>
<div class="footer ">
<div class="col">
<a href="https://www.facebook.com/matavaishnodevimandir/" ><i><BsFacebook style={{color:"#3C4041",width:30}}/></i></a>
<a href="#"><i><AiFillInstagram style={{color:"#3C4041",width:30}}/></i></a>
<a href="https://youtube.com/@MATAVAISHNODEVIMANDIRBATHINDA?si=YmO8bpqLi82VoxiA"><i><TfiYoutube style={{color:"#3C4041",width:30}}/></i></a>
<a href="#"><i><BsTwitter style={{color:"#3C4041",width:30}}/></i></a>
</div>

<div class="row">
<ul>
<li><a href="/chat"style={{marginTop:0}}>Contact Us </a></li>
<li><a href="/history">About Us</a></li>
<li><a href="/donate">Donate </a></li>
</ul>
</div>
<div>
    <hr class="solid"/>
</div>
<div class="col">
<a style={{fontSize:15,fontWeight: "bold"}}>Site By</a> <label style={{color:"#496DDB"}}> Pardeep Singla </label> <a style={{fontSize:15,fontWeight: "bold"}}>Under The Guidance of <label style={{color:"#496DDB"}}> Prof. Sushil Kumar Singla </label></a> 
</div>
</div>
</footer>
</div>
)
}
export default Footers; 