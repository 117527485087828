import React from "react";

const LocationEmbed = () => {
    return (
        <div className="embed-responsive embed-responsive-16by9">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3447.5596893265288!2d74.9662488!3d30.221112100000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391732b3672c3ea9%3A0xc6f50e85d97bafd1!2sMata%20Vaishno%20Devi%20Mandir!5e0!3m2!1sen!2sin!4v1694361459303!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowuFllScreen=""
                loading="lazy"
                referrerpolicy="no - referrer - when - downgrade" >
            </iframe>
        </div>
    );

}

export default LocationEmbed;