export default [

    {
        address: "Mata vaishno devi mandir ,Ring road Bathinda",
        description: "Every Tuesday 7:30 P.M. TO 8 P.M.",
        endTimestamp: 1608607800000,
        time:"Tuesday, 7:30 pm - 8:00 pm",
        id: "4lBi4k8knBL5j4",
        images: "https://res.cloudinary.com/dbcjiqct9/image/upload/v1694534035/tmplogo_nvjqm5.png",
        keywords: "durga puja maha shasthi evening",
        livestreamURL: "Jr-TPWOQjO0",
        livestreaming: false,
        startTimestamp: 1608595200000,
        title: "Hanuman Ji Chalisa",
    },
    {
        address: "Mata vaishno devi mandir ,Ring road Bathinda",
        description: "Everyday 6:30 P.M. TO 7:00 P.M.",
        endTimestamp: 1608607800000,
        id: "4lBi4k8knBLCBQ1m",
        images: "https://res.cloudinary.com/dbcjiqct9/image/upload/v1695044302/IMG-20230918-WA0009_qhhu6n.jpg",
        keywords: "durga puja maha shasthi evening",
        livestreamURL: "Jr-TPWOQjO0",
        livestreaming: false,
        time:"Everyday, 6:30 pm - 7:00 pm",
        startTimestamp: 1608595200000,
        title: "Aarti",
    },
    {
        address: "Mata vaishno devi mandir ,Ring road Bathinda",
        description: "19th October 2023 ",
        endTimestamp: 1608607800000,
        time:"Thrusday , September 14th 2023, 3:00 pm - 6:00 pm",
        id: "4lBi4k8kBQ1ma5j4",
        images: "https://res.cloudinary.com/dbcjiqct9/image/upload/v1694534035/tmplogo_nvjqm5.png",
        keywords: "",
        livestreamURL: "Jr-TPWOQjO0",
        livestreaming: false,
        startTimestamp: 1608595200000,
        title: "Jagran",
    },
    

]