import React, { useState } from 'react';
// import Scroll from './Scroll';
import SearchLists from './SearchList';
import {FaSearch} from "react-icons/fa";
function Search({ details }) {

  const [searchField, setSearchField] = useState("");
  const filteredPersons = details.filter(
    person => {
      return (
        person
        .title
        .toLowerCase()
        .includes(searchField.toLowerCase()) ||
        person
        .catalog
        .toLowerCase()
        .includes(searchField.toLowerCase())||
        person
        .category
        .toLowerCase()
        .includes(searchField.toLowerCase())||
        person
        .price
        .toString()
        .includes(searchField.toString())     
      );
    }
  );

  const handleChange = e => {
    e.preventDefault();
    setSearchField(e.target.value);
  };

  function searchList() {
    return (
 
        <SearchLists filteredPersons={filteredPersons} />
  
    );
  }

  return (
    <section className="garamond">
      <div className="navy georgia ma0 grow"style={{paddingLeft:28}}>
        <h2 className="f2">Search your Sneaker</h2>
      </div>
      <div className="pa2"style={{paddingLeft:30}}>
     <input
          className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
          type = "search" 
          placeholder = "Search Here" 
          onChange = {handleChange}
      
        />   
        <FaSearch style={{marginLeft:-40}}/>
      </div>
      {searchList()}
    </section>
  );
}

export default Search;