import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Navbar as BSNavbar } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import logo from "../../../MyPuja-Website-master/src/img/tmplogo.png";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import './navbar.css';
import { Hidden } from "@material-ui/core";
import NavDropdown from 'react-bootstrap/NavDropdown';


import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import { yellow } from "@material-ui/core/colors";


const Navbar = () => {
  

  return (

    <div id="navbar">
      <BSNavbar expand="lg " style={{ backgroundColor: "#3C4041" }} >
        <BSNavbar.Brand as={NavLink} to="/" exact style={{ color: "#e0dfd5" }}>
          <img src={logo} style={{ width: 70, height: 60, marginTop: -6 }} /> <p class="text-break" style={{fontWeight:"800",fontSize:12, wordWrap: 'break-word',paddingLeft:10}}>MATA VAISHNO DEVI MANDIR BATHINDA</p>
        </BSNavbar.Brand>
        <BSNavbar.Toggle  aria-controls="basic-navbar-nav" />
        <BSNavbar.Collapse id="basic-navbar-nav"style={{paddingLeft:50}}>
          <Nav className="mr-auto ">
            <Nav.Link as={NavLink} to="/" exact style={{ color: "#e0dfd5" }}>
              Home
            </Nav.Link>
            <Nav.Link as={NavLink} to="/events" style={{ color: "#e0dfd5" }}>
              Events
            </Nav.Link>
            <Nav.Link as={NavLink} to="/livestreams" style={{ color: "#e0dfd5" }}>
              Livestreams
            </Nav.Link>

            <Nav.Link as={NavLink} to="/donate" style={{ color: "#e0dfd5" }}>
              Donation
            </Nav.Link>
            <Nav.Link as={NavLink} to="/gallery" style={{ color: "#e0dfd5" }}>
              Gallery
            </Nav.Link>
            <Nav.Link as={NavLink} to="/chat" style={{ color: "#e0dfd5" }}>
             Contact Us
            </Nav.Link>


            <NavDropdown title="About" id="basic-nav-dropdown">
            <NavDropdown.Item href="/history">History</NavDropdown.Item>
            <NavDropdown.Item href="/about">Trusties</NavDropdown.Item>
            <NavDropdown.Item href="/executive">Executive Members</NavDropdown.Item>
            <NavDropdown.Item href="/panditji">Pandit Ji</NavDropdown.Item>
          </NavDropdown>

          </Nav>
        </BSNavbar.Collapse>
      </BSNavbar>

    </div>


  );
}
export default Navbar;
