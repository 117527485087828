import React, { Component } from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./gallery.css";
class History extends Component {
    render() {
        return (
            <div style={{paddingTop:10}}>
                 <div class="container text-center">
          <div class="row">
            <div class="col">
              <img src="https://res.cloudinary.com/dbcjiqct9/image/upload/v1695044430/PWJZEKQtk4mUI8mw3ScqpJn9wZbkQCVin6B_XnkKjoA__plaintext_638306404597217484_ttdhpj.jpg" class="img-fluid" alt="..." style={{ width: 800, height: 300, objectFit: "contain", marginTop: 10 }} />
            </div>
            <div class="col">

              <img src="https://res.cloudinary.com/dbcjiqct9/image/upload/v1695897000/gate_tm68wn.jpg" class="img-fluid" alt="..." style={{ width: 800, height: 300, objectFit: "contain", marginTop: 10 }} />
            </div>
          </div>
        </div>
                {/* <img src="https://res.cloudinary.com/dbcjiqct9/image/upload/v1695044430/PWJZEKQtk4mUI8mw3ScqpJn9wZbkQCVin6B_XnkKjoA__plaintext_638306404597217484_ttdhpj.jpg" style={{width:"100%",height:"100%",paddingLeft:100,paddingRight:100}}/> */}
                <div className="container"style={{textAlign: 'center', color: 'black', fontSize: 19, fontFamily: 'GT Walsheim Pro', fontWeight: '500',wordWrap: 'break-word' ,marginTop:10}}>
                <p><a style={{fontWeight:700,textDecoration:"underline"}}>Mata Vaishno Devi Mandir </a>is situated at the Ring Road, opposite army cantt, Patel Nagar, Bathinda </p>
                <p>The statues of Bhagwan Hanuman Ji, Shri Ram Family, Mata Vaishno Ji, Shri Radha Krishan Ji and of Shiva family were erected on <a style={{textDecoration:'underline'}}>5th Oct. 2008</a> by the then trusty with the cooperation, devotion and infinite efforts of the local colonies. </p>
                <p>The vision of the temple enlivens the eternal power amongst the devotees. </p>
                <p>Now this temple is the main centre of devotion and prayer in the city.</p>
            </div>
            </div>
        )



    }
}
export default History;