import React, { Component } from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./gallery.css";
class Gallery extends Component {
  render() {

    return (
      <div>
        <div class="container" style={{ marginTop: 50 }}>
          <h3>Take a Glimpse at mesmerizing events that took place at th heavenly temple "Mata Vaishno Devi Mandir Bathinda"</h3><br />
          <div class="row">

          <div class="col">
              <img src="https://lh5.googleusercontent.com/p/AF1QipN1WmVN0NDVTrf_Vhq-b6zYvuM214j6l5uqS8M=w223-h280-n-k-no-nu" style={{ width: 270, height: 200, objectFit: "cover", border: "5px solid white" }} />
            </div>
            <div class="col">
              <img src="https://lh3.googleusercontent.com/p/AF1QipPlqP8CcslposcR1FfDvADcGlvR1d1YlhsREpPy=s1360-w1360-h1020" style={{ width: 250, height: 200, objectFit: "cover", border: "5px solid white" }} />
            </div>
            <div class="col">
              <img src="https://lh5.googleusercontent.com/p/AF1QipO2nafp8fq7k4dF_gEcwwG1lJepoQtkg1AUt2DQ=w223-h160-n-k-no-nu" style={{ width: 250, height: 200, objectFit: "cover", border: "5px solid white" }} />
            </div>
            <div class="col">
              <img src="https://res.cloudinary.com/dbcjiqct9/image/upload/v1695044311/IMG-20230918-WA0012_uxpcuc.jpg" style={{ width: 250, height: 200, objectFit: "cover", border: "5px solid white" }} />
            </div>
          </div>
          <div class="row">
           
            
        
          </div><br/>
      
          <div class="row">
           
            <div class="col">
              <img src="https://res.cloudinary.com/dbcjiqct9/image/upload/v1695044302/IMG-20230918-WA0009_qhhu6n.jpg" style={{ width: 320, height: 200, objectFit: "cover", border: "5px solid white" }} />
            </div>
            <div class="col">
              <img src="https://res.cloudinary.com/dbcjiqct9/image/upload/v1695044430/PWJZEKQtk4mUI8mw3ScqpJn9wZbkQCVin6B_XnkKjoA__plaintext_638306404597217484_ttdhpj.jpg" style={{ width: 300, height: 200, objectFit: "cover", border: "5px solid white" }} />
            </div>
            <div class="col">
              <img src="https://res.cloudinary.com/dbcjiqct9/image/upload/v1695044311/IMG-20230918-WA0011_rituco.jpg" style={{ width: 250, height: 230, objectFit: "cover", border: "5px solid white" }} />
            </div>
            <div class="col">
              <img src="https://lh5.googleusercontent.com/p/AF1QipM9yCQHBTF_V7BSPbwPCUFdd0FngBjj5o6_XjPT=w223-h373-n-k-no-nu" style={{ width: 280, height: 220, objectFit: "cover", border: "5px solid white" }} />
            </div>
          </div><br/>
          <div class="row">
             
            <div class="col">
              <img src="https://lh5.googleusercontent.com/p/AF1QipOHx6DWRvEAbjQPtfQtJG74BbC3ntnEifqKNNBp=w223-h279-n-k-no-nu" style={{ width: 250, height: 250, objectFit: "cover", border: "5px solid white" }} />
            </div>
            
            <div class="col">
              <img src="https://res.cloudinary.com/dbcjiqct9/image/upload/v1695050357/WhatsApp_Image_2023-09-18_at_09.41.57_ej5mxu.jpg" style={{ width: 280, height: 200, objectFit: "cover", border: "5px solid white" }} />
            </div>
          
          </div><br/>
       
   
        </div><br/>
     </div>
    )
  }
}
export default Gallery;