import React, { Component, Suspense, lazy } from "react";
import "./App.css";
import firebase from "firebase/app";
import Gallery from "./components/Gallery.js";
import firestore from "firebase/firestore";
import Example from "./components/ex.jsx";
import History from "./components/history";
import EventData from "./pages/event1";
import PanditJi from "./components/PanditAbout";
// Components
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import Donate from "./pages/Donate";
// React Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Pages
import Loading from "./pages/Loading";
import Footers from "./components/footer";
import LocationEmbed from "./components/location";
import eventdata from "./eventdata";
import ChatWithUs from "./components/Chatwithus.jsx";
import Search from "./pages/Search";

class App extends Component {
  
  state = {
    cards: [],
    hasRecievedData: false,
    livestreamURL: "",
  };


  componentDidMount() {



    const postsRef = firebase.firestore().collection("events");

 
    const exportQueriesToState = (querySnapshot) => {
      let cards = [];
      querySnapshot.forEach((doc) => {
        // Add each card to the local array
        cards.push({
          id: doc.id,
          title: doc.data().title,
          description: doc.data().description,
          startTimestamp: doc.data().start_timestamp.seconds * 1000,
          endTimestamp: doc.data().end_timestamp.seconds * 1000,
          images: doc.data().images,
          address: doc.data().address,
          livestreaming: doc.data().livestreaming,
          livestreamURL: doc.data().livestream_url,
          keywords: doc.data().keywords,
        });

        if (doc.data().livestream_url !== "") {
          this.setState({ livestreamURL: doc.data().livestream_url });
        }
      });

  
      this.setState({ cards });
      this.setState({ hasRecievedData: true });
    };


    postsRef.onSnapshot({ includeMetadataChanges: true }, exportQueriesToState);
  }

  render() {
    const Events = lazy(() => import("./pages/Events"));
    const EventDetails = lazy(() => import("./pages/EventDetails"));
    const LandingPage = lazy(() => import("./pages/NewLandingPage"));
    const About = lazy(() => import("./pages/About.js"));
    const Executive = lazy(() => import("./pages/executive.js"));
    const Livestreams = lazy(() => import("./pages/Livestreams"));
    const Page404 = lazy(() => import("./pages/Page404"));
    
    return (
      <Router>
        <ScrollToTop />
        {/* <Donate/> */}
        <div className="App"style={{backgroundColor:"#E0DFD5"}}>
          <Navbar />

          <Switch>
            <Route
              path="/"
              exact
              component={(routerProps) => (
                <Suspense fallback={Loading()}>
                  <LandingPage
                    {...routerProps}
                    livestreaming={this.state.cards.filter(
                      (doc) => doc.livestreaming
                    )}
                  />
                </Suspense>
              )}
            />

            <Route
              path="/about"
              exact
              component={(routerProps) => (
                <Suspense fallback={Loading()}>
                  <About {...routerProps} />
                </Suspense>
              )}
            />
              <Route
              path="/executive"
              exact
              component={(routerProps) => (
                <Suspense fallback={Loading()}>
                  <Executive {...routerProps} />
                </Suspense>
              )}
            />
<Route path="/search" element={<Search details={eventdata}/>}/>
            <Route
              path="/events"
              exact
              component={(routerProps) => (
                <Suspense fallback={Loading()}>
                  <EventData
                    {...routerProps}
                    cards={this.state.cards}
                    onSearchBarChange={this.handleSearchBarInput}
                    finishedLoading={this.state.hasRecievedData}
                  />
                </Suspense>
              )}
            />

            <Route
              path="/livestreams"
              exact
              component={(routerProps) => (
                <Suspense fallback={Loading()}>
                  <Livestreams {...routerProps} events={this.state.cards} />
                </Suspense>
              )}
            />

            <Route
              path="/events/:id"
              exact
              component={(routerProps) => (
                <Suspense fallback={Loading()}>
                  <EventDetails
                    {...routerProps}
                    event={eventdata.filter(
                      (x) => x.id === routerProps.match.params.id
                    )}
                    finishedLoading={this.state.hasRecievedData}
                  />


                </Suspense>
              )}
            />
            
            TODO: Add donate functionality later 
            <Route path="/donate" exact component={Donate} />
           {/* <Route path="/example" element={<Example/>}/> */}
           <Route path="/ex" exact component={Example} />  
            <Route path="/chat" exact component={ChatWithUs} />
            <Route path="/gallery" exact component={Gallery} />
            <Route path="/history" exact component={History} />
            <Route path="/panditji" exact component={PanditJi} />
            <Route
              path=""
              exact
              component={(routerProps) => (
                <Suspense fallback={Loading()}>
                  <Page404 {...routerProps} />
                </Suspense>
              )}
            />
          </Switch><br/>
      {/* <ChatWithUs/><br/> */}
     {/* <LocationEmbed/>  */}
     {/* <EventData/> */}
   <Footers/>
   
        </div>
      </Router>
    );
  }
}

export default App;
