import React from 'react';
import EventData from './event1';
import { Card } from 'react-bootstrap';

function SearchLists({ filteredPersons }) {
  const filtered = filteredPersons.map(person =>  <EventData key={person.id} person={person} />); 
  return (
    <div>
      {filtered}
    </div>
  );
}

export default SearchLists;