import React from "react";
import eventdata from "../eventdata";
import Card from "../components/Card";
import moment from "moment";
import { Collapse } from "react-bootstrap";
import Search from "./Search";
const items = eventdata;
console.log(items);
const EventData = () => {
    return (

        <div className="container"style={{marginTop:-50}}>
        <div class="py-5 text-center" style={{textDecoration:"underline"}}>
          <h1>Events</h1>
          </div>
            {/* 
            <div className="myRow">
                {items.map((item) => (
                    <h3> {item.description}</h3>
                ))}
            </div> */}
            {/* {items
                .map((card) => (
                    <Card key={items.id} card={card} />
                ))} */}




            {/* {items.map((items) => (
                <Collapse in={items}>
                    <div className="card-columns justify-content-center">
                        <div className="card" id={items.id}>

                            <img
                                src={items.images}
                                className="card-img-top"
                                alt=""
                                style={{
                                    objectFit: "contain",
                                    maxWidth: "300px",
                                    maxHeight: "300px",
                                    margin: "auto",
                                    padding: "30px 30px 30px 30px",
                                }}
                            />
                            <div className="card-body">
                                <h5 className="card-title">{items.title}</h5>
                                <p className="card-text">{items.description}</p>


                                <Link to={`/events/${items.id}`}>
                                    <button className="btn btn-info mx-1 my-1">
                                        More Information
                                    </button>
                                </Link>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">
                                    <p>Time: {moment(startDatetime).utcOffset(-240).format("dddd, MMMM Do YYYY, h:mm a")} - {moment(endDatetime).utcOffset(-240).format("h:mm a")}</p>
                                </small>
                            </div>
                        </div>
                    </div>
                </Collapse>
            ))} */}
{/* <Search/> */}
            <Collapse in={items}>
                <div>
                    <br />
                    <div className="card-columns justify-content-center">
                        {items
                            .filter((card) => card.startTimestamp < Date.now())
                            .map((card) => (
                                <Card key={card.id} card={card} />
                            ))}
                    </div>
                </div>
            </Collapse>
        </div>


    );

};

export default EventData;