import React, { Component } from "react";
import ReactWhatsapp from 'react-whatsapp';
import { BsWhatsapp } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";
class ChatWithUs extends Component {
    render() {
        return (
            <div className="container"><br />
                <h3 style={{ textAlign: 'center', fontSize: 24, fontFamily: 'GT Walsheim Pro', fontWeight: '700', wordWrap: 'break-word' }}>Contact Us for Booking Hall <br /> for Aarti And Poojan</h3>

                <div style={{ width: "100%" }}><br /><br />
                    <ReactWhatsapp number="+91 9417138809" class="btn btn-outline-dark" message="Hello " style={{ justifyContent: "right", alignItems: "right" }} >
                        <BsWhatsapp style={{ color: "green", marginBottom: 2, marginRight: 5, width: 28, height: 28 }} />Chat With Us</ReactWhatsapp>
                    <a href="tel:+91  9417138809" class="btn btn-outline-dark" style={{ marginLeft: 10, height: 42 }} >
                        <BiSolidPhoneCall style={{ color: "green", marginBottom: 2, marginRight: 5, width: 28, height: 28 }} />Call Us</a><br /><br /><br /><br /><br /><br /><br />
                </div>
            </div>
        )
    }
}
export default ChatWithUs;