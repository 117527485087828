import { Component } from "react";
import React from "react";
import img1 from "../../src/img/panditji.jpg";
import img2 from "../../src/img/panditji1.jpg";
import panditjievent from "../../src/img/panditjievent.jpg";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

class PanditJi extends Component {
  render() {
    return (
      <div>

        <div class="container text-center">
          <div class="row">
            <div class="col">
              <img src={img2} class="img-fluid" alt="..." style={{ width: 500, height: 500, objectFit: "cover", marginTop: 10 }} />
            </div>
            <div class="col">

              <img src={img1} class="img-fluid" alt="..." style={{ width: 500, height: 500, objectFit: "cover", marginTop: 10 }} />
            </div>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <h5>Name:  <a style={{ fontWeight: 490,textDecoration:"underline" }}>Pandit Vijay Gaud.</a></h5>
              <h5>Contact Number:  <a style={{ fontWeight: 490,textDecoration:"underline" }}>90234-60359</a></h5>

            </div>
          </div>
        </div>
        <img src={panditjievent} class="img-fluid" alt="..."/>

      </div>
    )
  }



}
export default PanditJi;