import React, { Component } from "react";
import Donateqr from "../img/donationQr.jpg";
import './Donate.css';
function notify() {
  alert("Thanks for Donating To Mandir , Please Collect Your Receipt In 2 days.")
};
class Donate extends Component {

  state = { amount: 0 };

  render() {
    return (
      <div className="container" style={{ marginTop: 0 }}>
        <h1 >Donate Us</h1>
        <div class=" qrsection py-5 text-center" style={{ width: "100%", height: "100%" }}>
          <div class="container" style={{ marginTop: -35 }}>
            <div class="row" >
              <div class="col-5">
              <h2 style={{textDecoration:"underline"}}>QR CODE:-</h2>
              <img src={Donateqr} style={{ width: "100%", height:"100", mixBlendMode:"darken" }} />
              </div>
              <div class="col-7">
                <h2 style={{textDecoration:"underline"}}>Bank Details:-</h2>
                <h3 style={{textDecoration:"underline"}}>Branch Name: </h3><h4> State Bank Of India,Bibi Wala Road.</h4>
               <h3 style={{textDecoration:"underline"}}>Account Name:</h3><h4> MATA VAISHNO DEVI MANDIR TRUST[REGD]</h4>
               <h3 style={{textDecoration:"underline"}}>Account No.:</h3><h4>  65115749446</h4>
               <h3 style={{textDecoration:"underline"}}>IFSC CODE: </h3><h4> SBIN0050657</h4>
              </div>
            </div>
          </div>



        </div>
        <p class="lead"style={{ marginTop: -60 }}>
          Thank you for considering to donate to the Mandir ! Please
          input your information below, and you'll be e-mailed a reciept.
        </p>
        <form className="needs-validation" novalidate="" _lpchecked="1" action="https://formspree.io/f/mleyoeaq" method="POST" >
          <div className="row">
            <div className="col-md-6 mb-3">
              <label for="firstName" className="float-left">
               Enter Your Name
              </label>
              <input
                type="text"
                className="form-control"
                name="Name"
                placeholder=""
                required
                style={{ cursor: "auto" }}
              />
              <div className="invalid-feedback">
                Valid name is required.
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label for="email" className="float-left">
              Email
              </label>
              <input
                type="email"
                className="form-control"
                name="E-mail"
                placeholder="you@example.com"
                required
              />
              <div className="invalid-feedback">
              Please enter a valid email address.
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-3">
            <label for="phone number" className="float-left">
             Phone No.
            </label>
            <input
              type="phone no."
              className="form-control"
              name="Phone Number"
              placeholder=""
              required
            />
            <div className="invalid-feedback">
              Please enter a valid Phone Number.
            </div>
          </div>

          <div className="mb-3">
            <label for="address" className="float-left">
              Address
            </label>
            <input
              type="text"
              className="form-control"
              name="Address"
              placeholder="1234 Main St"
              required=""
            />
            <div className="invalid-feedback">
              Please enter your shipping address.
            </div>
          </div>

          {/* <div className="mb-3">
            <label for="address2" className="float-left">
              Address 2 <span className="text-muted">(Optional)</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="Address2"
              placeholder="Apartment or suite"
              required
            />
          </div> */}

          <div className="row">
            {/* <div className="col-md-3 mb-3">
              <label for="postal" className="float-left">
                Postal code
              </label>
              <input
                type="text"
                className="form-control"
                name="Postal Code"
                placeholder=""
                required=""
              />
              <div className="invalid-feedback">Postal code required.</div>
            </div> */}

            <div className="col-md-3 mb-3">
              <label for="money" className="float-left">
                Enter the Amount You Donated
              </label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Rs.</span>
                </div>
                <input
                  type="number"
                  value={this.state.amount}
                  name="Donate"
                  onChange={(event) =>
                    this.setState({
                      amount: event.target.value.replace(/\D/, ""),
                    })
                  }
                  className="form-control"
                  required
                />
                <div class="input-group-append">
                  <span class="input-group-text">.00</span>
                </div>
              </div>
              <div className="invalid-feedback">
                Amount of money must be larger than $0.
              </div>
            </div>
          </div>

          <hr className="mb-4" />
          <div className="custom-control custom-checkbox">
            {/* <input
              type="checkbox"
              className="custom-control-input"
              id="same-address"
              required
            />
            <label
              className="custom-control-label float-left"
              for="same-address"
            >
              Shipping address is the same as my billing address
            </label> */}
          </div>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="save-info"
              required
            />
            <label className="custom-control-label float-left" for="save-info">
              Save this information for next time
            </label>
          </div>
          <hr className="mb-4" />
          <button onClick={notify} className="btn btn-primary btn-lg btn-block" type="submit">
            Continue 
          </button>
        </form>
      </div>
    );

  }

}

export default Donate;
